
/**
 * @description
 * 숫자 올라가는 애니메이션 컴포넌트
 */

import ICountUp from 'vue-countup-v2';

export default {
  name: 'IncreaseNumber',
  components: { ICountUp },
  props: {
    // 시작 딜레이
    delay: { type: Number, default: 0 },
    // 종료 숫자
    endVal: { type: Number, default: 10000 },
    // 총 애니메이션 길이
    duration: { type: Number, default: 2 },
    startAnim: { type: Boolean, default: true },
  },
  data() {
    return {
      defaultOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    };
  },
  computed: {
    options: ({ defaultOptions, duration }) => ({
      ...defaultOptions,
      duration,
    }),
  },
};

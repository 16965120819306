
/**
 * @description
 * 홈 > 공용 > 블로그 아이템
 */
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';

export default {
  name: 'EducationInfoItem',
  props: {
    thumbnail: { type: String, required: true },
    title: { type: String, required: true },
    date: { type: String, required: true },
    category: { type: String, required: true },
  },
  data() {
    return {
      defaultImgUrl: '/images/development/default-thumbnail.jpg',
    };
  },
  computed: {
    convertDate() {
      return makeDateTextFormatKo(new Date(this.date), 'yyyy. MM. dd.');
    },
  },
  methods: {
    onImageLoadFail(event) {
      event.target.src = this.defaultImgUrl;
    },
  },
};

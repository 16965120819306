

import {ChristmasEventUrl} from "~/utils/christmasProduct";

export default {
  name: 'ChristmasEvent',
  components: {
  },
  data () {
    return {
      giftBox: require('@/assets/img/christmas/giftbox.png'),
      tooltipContent: require('@/assets/img/christmas/tooltipContent.svg'),
      showTooltipContent: false,
    };
  },
  methods: {
    handleImageShow() {
      this.showTooltipContent = true;
    },
    handleImageOff() {
      this.showTooltipContent = false;
    },
    handleEvent() {
      window.location = ChristmasEventUrl;
    }
  }
};

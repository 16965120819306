
/**
 * @description
 * 홈 > 타입별 랭킹 큐레이션 섹션
 */
import {
  CLICK_PRODUCT_RANKING,
  CLICK_RANKING_MORE,
  CLICK_RANKING_TAB,
  VIEW_RANKING_PAGE,
} from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { getHomeRankingTabList } from '@/utils/ranking/homeRankingUtils.js';
import { learningTypeFilter } from '@/utils/ranking/rankingUtils.js';
import { safeLowerCase } from '@/utils/safeStringUtils.js';
import { mapActions } from 'vuex';

import RankingCurationSkeleton from './RankingCurationSkeleton.vue';
import RankingCurationWrapper from './RankingCurationWrapper.vue';
import RankingHeader from './RankingHeader.vue';

export default {
  name: 'HomeRanking',
  components: {
    RankingHeader,
    RankingCurationWrapper,
    RankingCurationSkeleton,
  },
  props: {
    learningType: {
      type: String,
      required: true,
      validator: value =>
        learningTypeFilter.map(lt => lt.value).includes(value),
    },
  },
  data: ({ learningType }) => {
    const rankingList = getHomeRankingTabList(learningType);

    return {
      rankingSectionName: NEW_HOME_SECTION_NAME.HOME_RANKING,
      rankingTabList: rankingList,
      selectedTabValue: rankingList[0].value,
      isError: false, // api 오류시 true로 세팅하고 영역을 그리지 않게 하기
      rankingClassList: [],
      selectedTabIndex: 0,
      intervalDefaultTime: 5000, // 5초마다 자동 탭 전환
      intervalTime: 5000, // 5초 타이머
      intervalTimer: null, // 인터벌 객체
      slideIsPending: false, // swiper slide가 움직이는중인지 추적
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const res = await this.getHomeRankingClassAll({
        learningType: this.learningType,
      });

      // res가 빈 값으로 넘어오면 error로 보내 에러처리를 해줍니다.
      if (!res) throw new Error('no class');

      Object.entries(res).forEach(([key, value]) => {
        const currentTab = this.rankingTabList.find(rtl => rtl.value === key);
        currentTab.classList = value;
      });

      this.rankingClassList = res[this.selectedTabValue];
    } catch (e) {
      console.error(e);
      this.isError = true;
      this.rankingClassList = [];
    }
  },
  computed: {
    selectedTab: ({ rankingTabList, selectedTabValue }) =>
      rankingTabList.find(rtl => rtl.value === selectedTabValue),
  },
  mounted() {
    this.setIntervalTimer();
  },
  beforeDestroy() {
    this.removeTimer();
  },
  methods: {
    ...mapActions('ranking', ['getHomeRankingClassAll']),
    // 랭킹아이템 클릭 이벤트
    logClickEvent({ id, title, teacherId, teacherName, rank }) {
      this.logUserCustomEvent(CLICK_PRODUCT_RANKING, {
        learning_type: safeLowerCase(this.learningType),
        product_name: title,
        teacher_name: teacherName,
        teacher_id: teacherId,
        rank,
        product_id: id,
        page_type: 'home', // 홈 화면에서 쓰이는 컴포넌트라 home으로 고정
        sub_ranking_type: safeLowerCase(this.selectedTab.value),
        ranking_term: 'weekly', // 홈 화면은 주간만 표기합니다
      });
    },
    // 탭 전환 이벤트
    changeSelectedTab(tabValue, tabIndex) {
      // 1. 선택 탭 변경
      this.selectedTabValue = tabValue;
      this.selectedTabIndex = tabIndex;

      // 2. 탭에 맞는 리스트로 변경해줍니다.
      this.rankingClassList = this.rankingTabList.find(
        rtl => rtl.value === tabValue,
      ).classList;
    },
    selectRankingTab(tabValue, tabIndex) {
      // 1. 탭 전환 타이머 리셋
      this.resetTimer();

      // 2. 만약 이미 선택된 값이라면 타이머만 리셋하고 얼리 리턴
      if (this.selectedTabValue === tabValue) return;

      // 3. 탭 전환 이벤트 호출
      this.changeSelectedTab(tabValue, tabIndex);

      // 4. 로깅을 찍어줍니다.
      this.logUserCustomEvent(CLICK_RANKING_TAB, {
        learning_type: safeLowerCase(this.learningType),
        ranking_type: safeLowerCase(tabValue),
      });
    },
    // 더보기
    handleClickMore() {
      // 이벤트 로깅
      this.logUserCustomEvent(CLICK_RANKING_MORE, {
        learning_type: safeLowerCase(this.learningType),
        ranking_type: safeLowerCase(this.selectedTab?.value),
      });

      // 라우트 이동
      this.$router.push({
        name: 'ranking',
        query: this.selectedTab?.query || {},
      });
    },
    /**
     * @description
     * 5초마다 탭 전환이 이뤄지는 오토 타이머
     */
    setIntervalTimer() {
      this.intervalTimer = setInterval(() => {
        // 슬라이드 이동중이라 타이머 일시 정지
        if (this.slideIsPending) {
          return;
        }

        this.intervalTime = this.intervalTime - 1000;

        // 타이머 종료시 탭 전환과 타이머 리셋을 해줍니다.
        if (this.intervalTime < 0) {
          // 5초? 6초?
          this.changeNextTab();
          this.resetTimer();
        }
      }, 1000);
    },
    // 타이머 기본값으로 초기화
    resetTimer() {
      this.intervalTime = this.intervalDefaultTime;
    },
    // 타이머 제거
    removeTimer() {
      clearInterval(this.intervalTimer);
    },
    // 다음 탭으로 이동
    changeNextTab() {
      const selectedTabIndex = this.rankingTabList.findIndex(
        rtl => rtl.value === this.selectedTabValue,
      );
      const nextTabIndex = (selectedTabIndex + 1) % this.rankingTabList.length;
      const { value } = this.rankingTabList[nextTabIndex];

      if (!value) return;

      this.resetTimer();
      this.changeSelectedTab(value, nextTabIndex);
    },
    handleTouchStart() {
      // 타이머 멈춤
      this.slideIsPending = true;
    },
    handleTouchEnd() {
      // 타이머 다시 동작
      this.resetTimer();
      this.slideIsPending = false;
    },
    handleSlideChange(activeIndex) {
      // custom 이벤트
      this.logUserCustomEvent(VIEW_RANKING_PAGE, {
        learning_type: safeLowerCase(this.learningType),
        ranking_type: safeLowerCase(this.selectedTab?.value),
        page: activeIndex + 1,
      });

      // slide가 움직이는 상황에선 타이머를 멈추기 위해 false로
      this.slideIsPending = false;
    },
    handleClickSwiperButton() {
      // 타이머 리셋
      this.resetTimer();
    },
  },
};


/**
 * @description
 * 홈 랭킹 > 선택된 텝 타이틀
 */

import { CLICK_RANKING_ALL } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import {
  desktopNextButtonClass,
  desktopPrevButtonClass,
} from '@/utils/ranking/homeRankingUtils.js';
import { safeLowerCase } from '@/utils/safeStringUtils.js';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'RankingTabTitle',
  components: { Icon },
  props: {
    learningType: { type: String, required: true },
  },
  data: () => ({
    desktopPrevButtonClass,
    desktopNextButtonClass,
  }),
  computed: {
    rankingPageRoute: ({ learningType }) => ({
      name: 'ranking',
      query: { learningType },
    }),
  },
  methods: {
    logViewAll() {
      this.logUserCustomEvent(CLICK_RANKING_ALL, {
        learning_type: safeLowerCase(this.learningType),
      });
    },
  },
};

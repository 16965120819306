
import ProgressSpinner from '@/components/common/ProgressSpinner.vue';

export default {
  name: 'CurationProgressSection',
  components: { ProgressSpinner },
  props: {
    type: { type: String, default: 'curation' },
  },
};


/**
 * @description
 * 홈 랭킹 > 랭킹 아이템이 없을 때 보여줄 에러 컴포넌트
 */

import ggugeEmoji from '@/utils/emoji.js';

export default {
  name: 'NoRankingItemError',
  data: () => {
    const emojiList = ggugeEmoji.getEmojiList();

    const randomIndex = Math.floor(Math.random() * emojiList.length);

    return {
      selectedEmoji: require(`~/assets/img/gguri/${emojiList[randomIndex]?.imgSrcId}`),
    };
  },
};


/**
 * @description
 * 홈 > 랭킹 > 탭과 타이틀 헤더 부분
 */
import FilterIndicatorTabBar from '@/components/ranking/filterIndicatorTabBar/FilterIndicatorTabBar.vue';
import RankingTabTitle from './RankingTabTitle.vue';

export default {
  name: 'RankingHeader',
  components: { RankingTabTitle, FilterIndicatorTabBar },
  props: {
    learningType: { type: String, required: true },
    rankingTabList: { type: Array, required: true, default: () => [] },
    isPending: { type: Boolean, required: true, default: false }, // fetch api 상태 확인
    selectedTabIndex: { type: Number, required: true, default: 0 },
  },
  computed: {
    selectedTabDescription: ({ rankingTabList, selectedTabIndex }) =>
      rankingTabList[selectedTabIndex]?.description ?? null,
  },
  methods: {
    onClickTab(tabValue, tabIndex) {
      this.$emit('select-tab', tabValue, tabIndex);
    },
  },
};


/**
 * @description
 * 홈 > 학습 > 꾸그가 보장한 BEST 선생님
 */

import { mapActions } from 'vuex';

import TeacherCuration from '../../common/teacher/TeacherCuration.vue';

export default {
  name: 'Teacher',
  components: { TeacherCuration },
  data() {
    return {
      bestTeacherList: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.bestTeacherList = await this.getBestTeacherList({ isTalent: false });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home', ['getBestTeacherList']),
  },
};

const safeLowerCase = string => {
  try {
    return string.toLowerCase();
  } catch (e) {
    console.error(
      `변환 오류로 ${string}값을 그대로 내리는중입니다. 확인해주세요`,
    );
    return string;
  }
};

export { safeLowerCase };

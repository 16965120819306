
/**
 * @description
 * 홈 > 비로그인 유저에게 보이는 최하단 영역 이미지
 */

import StaccatoChar from '@/components/new-home/layout/StaccatoChar.vue';

export default {
  name: 'SocialProofImage',
  components: { StaccatoChar },
  data() {
    return {
      socialImage: require('~/assets/img/main-page/social-proof.png'),
    };
  },
};

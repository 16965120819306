
/**
 * @description
 * 랭킹에 쓰이는 인디케이터 탭바
 * TextTabBar와 거의 동일하나 스타일과 selectedTabIdx 동작이 조금 다릅니다.
 */
export default {
  name: 'FilterIndicatorTabBar',
  props: {
    selectedTabIdx: { type: Number, default: 0 },
    tabList: { type: Array, required: true, default: () => [] },
    isFetching: { type: Boolean, default: false },
  },
  data() {
    return {
      indicatorWidth: 100,
      indicatorX: 0,
    };
  },
  watch: {
    selectedTabIdx(v) {
      this.$nextTick(() => {
        this.setIndicatorStyle(v);
      });
    },
  },
  mounted() {
    this.setIndicatorStyle();
    window.addEventListener('resize', this.setIndicatorStyle);
  },
  destroyed() {
    window.removeEventListener('resize', this.setIndicatorStyle);
  },
  methods: {
    setIndicatorStyle(selectedTabIdx) {
      const tabIdx = selectedTabIdx || this.selectedTabIdx;
      if (tabIdx < 0) return;
      const textTabList = this.$refs.textTabList;
      if (textTabList && textTabList[tabIdx]) {
        this.indicatorX = textTabList[tabIdx].offsetLeft;
        this.indicatorWidth = textTabList[tabIdx].offsetWidth;
      }
    },
    onClickTab(tabValue, tabIndex) {
      this.$emit('change', tabValue, tabIndex);
    },
  },
};

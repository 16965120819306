
/**
 * @description
 * 홈 > 학습 > 학년별 교육과정 정복하기
 */

import GradeItem from './GradeItem.vue';

export default {
  name: 'CurriculumByGrade',
  components: { GradeItem },
  data() {
    return {
      gradeList: [
        {
          title: '예비초',
          slug: 'home-pre',
          icon: require('@/assets/img/home/grade-1.png'),
        },
        {
          title: '초등<br>1,2학년',
          slug: 'home-primary1-2',
          icon: require('@/assets/img/home/grade-2.png'),
        },
        {
          title: '초등<br>3,4학년',
          slug: 'home-primary3-4',
          icon: require('@/assets/img/home/grade-2.png'),
        },
        {
          title: '초등<br>5,6학년',
          slug: 'home-primary5-6',
          icon: require('@/assets/img/home/grade-2.png'),
        },
        {
          title: '중등<br>이상',
          slug: 'home-middle',
          icon: require('@/assets/img/home/grade-3.png'),
        },
      ],
    };
  },
};

import { render, staticRenderFns } from "./ProductCuration.vue?vue&type=template&id=61b07ac8&scoped=true"
import script from "./ProductCuration.vue?vue&type=script&lang=js"
export * from "./ProductCuration.vue?vue&type=script&lang=js"
import style0 from "./ProductCuration.vue?vue&type=style&index=0&id=61b07ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b07ac8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductItem: require('/codebuild/output/src2597364184/src/components/product-item/ProductItem.vue').default})

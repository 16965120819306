// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/christmas/banner360x64.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/christmas/banner639x64.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/christmas/banner640x80.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/img/christmas/banner985x80.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".christmas-event[data-v-b989eac0]{background-color:#fff;cursor:pointer;left:0;position:absolute;right:0;width:100%}@media(max-width:360px){.christmas-event[data-v-b989eac0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain;height:64px}}@media(min-width:361px)and (max-width:639px){.christmas-event[data-v-b989eac0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain;height:64px}}@media(width:640px){.christmas-event[data-v-b989eac0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:contain;height:80px}}@media(min-width:641px)and (max-width:985px){.christmas-event[data-v-b989eac0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;background-size:contain;height:80px}}.gift-box[data-v-b989eac0]{bottom:240px;cursor:pointer;height:120px;position:fixed;right:13px;width:120px;z-index:101}@media(max-width:985px){.gift-box[data-v-b989eac0]{display:none}}.tooltip[data-v-b989eac0]{bottom:239px;height:120px;position:fixed;right:13px;width:120px;z-index:101}@media(max-width:985px){.tooltip[data-v-b989eac0]{display:none}}.tooltip-content[data-v-b989eac0]{bottom:348px;position:fixed;right:11px;z-index:102}@media(max-width:985px){.tooltip-content[data-v-b989eac0]{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;


/**
 * @description
 * 신규 홈 화면
 * 쿼리에 따라 재능 / 학습을 나눕니다
 */

import { PAGEVIEW_HOME } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import {
  APP_STORE_LINK,
  INSTARGRAM_LINK,
  NAVER_BLOG_LINK,
  PLAY_STORE_LINK,
  YOUTUBE_LINK,
} from '@/utils/linkPath.js';
import { SITE_KEYWORDS } from '@/utils/strings.js';

import { getLastHomeType } from '@/utils/cookieUtils';

import Education from './-education.vue';
import Talent from './-talent.vue';

export default {
  name: 'Index',
  components: { Talent, Education },
  layout: 'homeLayout',
  async asyncData({ query, store, req }) {
    const lastHomeType = getLastHomeType(req);

    const isTalentPage =
      (query?.tab === undefined && lastHomeType !== 'education') ||
      query?.tab === 'talent';

    const keywordList = await store.dispatch('home/getMetaTagList');

    return {
      isTalentPage,
      siteKeywords: SITE_KEYWORDS + keywordList.join(','),
    };
  },
  data() {
    return {
      isTalentPage: false,
      siteKeywords: null,
    };
  },
  jsonld() {
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: '꾸그',
      url: 'https://www.gguge.com',
      sameAs: [
        INSTARGRAM_LINK,
        YOUTUBE_LINK,
        NAVER_BLOG_LINK,
        PLAY_STORE_LINK,
        APP_STORE_LINK,
      ],
    };
  },
  head() {
    return {
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.siteKeywords,
        },
      ],
      link: [{ rel: 'canonical', href: 'https://www.gguge.com' }],
    };
  },
  watch: {
    $route({ query }) {
      const isTalentPage = query?.tab === undefined || query?.tab === 'talent';
      this.isTalentPage = isTalentPage;
      this.logPageviewHome();
    },
  },
  beforeDestroy() {
    this.hideChannelTalkBtn();
  },
  mounted() {
    this.showChannelTalkBtn();
    this.logPageviewHome();
  },
  methods: {
    logPageviewHome() {
      const ampLogObject = this.getUtmQuery();

      ampLogObject.home_type = this.isTalentPage ? 'talent' : 'education';

      // event logs
      this.logUserCustomEvent(PAGEVIEW_HOME, ampLogObject);
    },
  },
};

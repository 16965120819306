
/**
 * @description
 * 홈 > 재능 > 고정 큐레이션 3종
 */

import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { mapActions } from 'vuex';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'FixedCuration',
  components: { ProductCuration },
  data: () => {
    return {
      fixedCurationList: [],
      homeType: HOME_TYPE.TALENT,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.fixedCurationList = await this.getFixedCurationList({
        isTalent: true,
        size: 3,
      });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home', ['getFixedCurationList']),
  },
};


/**
 * @description
 * 홈 화면 랭킹 > 큐레이션 데스크탑 버전
 */

import {
  desktopNextButtonClass,
  desktopPrevButtonClass,
} from '@/utils/ranking/homeRankingUtils.js';
import { Swiper } from 'vue-awesome-swiper';

import RankingList from '@/components/new-home/common/ranking/RankingList.vue';
import RankingMoreButton from '@/components/new-home/common/ranking/RankingMoreButton.vue';

export default {
  name: 'RankingCurationDesktop',
  components: { Swiper, RankingList, RankingMoreButton },
  props: {
    rankingClassList: { type: Array, required: true, default: () => [] },
  },
  data() {
    return {
      desktopRankingCurationRef: null,
      rankingCurationOptions: {
        slidesPerView: 'auto',
        grabCursor: true,
        effect: 'slide',
        mouseWheel: false,
        spaceBetween: 24,
        navigation: {
          nextEl: `.${desktopNextButtonClass}`,
          prevEl: `.${desktopPrevButtonClass}`,
        },
      },
    };
  },
  computed: {
    rankingList: ({ rankingClassList }) => [
      rankingClassList.slice(0, 10),
      rankingClassList.slice(10),
    ],
    activeIndex: ({ desktopRankingCurationRef }) =>
      desktopRankingCurationRef?.activeIndex || 0,
  },
  watch: {
    rankingClassList() {
      this.desktopRankingCurationRef &&
        this.desktopRankingCurationRef.slideTo(0);
    },
  },
  mounted() {
    // swiper ref에 바인딩
    this.desktopRankingCurationRef = this.$refs.desktopRankingCuration?.$swiper;
  },
  methods: {
    logClickEvent(rankingItem) {
      this.$emit('log-click-event', rankingItem);
    },
  },
};

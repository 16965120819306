
/**
 * @description
 * 홈 > 공용 > 블로그 큐레이션
 */
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { getPathAt } from '@/utils/path';
import { mapActions } from 'vuex';
import { CLICK_BLOG } from '@/utils/amplitudeEvent/amplitudeEvents.js';

import CurationProgressSection from '@/components/common/CurationProgressSection.vue';
import Swiper from '@/components/common/swiper/CssSwiper.vue';
import EducationInfoItem from './EducationInfoItem.vue';

export default {
  name: 'EducationInfoSection',
  components: { Swiper, CurationProgressSection, EducationInfoItem },
  props: {
    homeType: { type: String, default: null },
  },
  data() {
    return {
      blogItems: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const blogs = await this.getBlogs({
        page: 1,
        categoryId: undefined,
        limit: 10,
      });

      const categories = await this.getCategories();

      this.blogItems = blogs.data.map(item => {
        return {
          ...item,
          category: item.categories
            ? categories.find(category => category.id === item.categories[0])
                .name
            : [],
        };
      });
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    blogContentSectionName: () => NEW_HOME_SECTION_NAME.BLOG_CONTENT,
  },
  methods: {
    ...mapActions('blog', ['getBlogs', 'getCategories']),
    logBlogEvent({ blogId }) {
      this.logUserCustomEvent(CLICK_BLOG, {
        blog_id: blogId,
        at: getPathAt(this.$route),
        // home_type: this.isTalent ? HOME_TYPE.TALENT : HOME_TYPE.EDUCATION,
        home_type: this.homeType,
      });
    },
  },
};

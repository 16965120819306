
/**
 * @description
 * 홈 > 학습 > 최상위 가중치 가장 높은 큐레이션 1종
 */

import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { mapActions, mapMutations } from 'vuex';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'FirstFixedCuration',
  components: { ProductCuration },
  data: () => {
    return {
      curationProducts: [],
      title: null,
      id: 0,
      homeType: HOME_TYPE.EDUCATION,
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const educationCurationList = await this.getFixedCurationList({
        isTalent: false,
        size: 4,
      });

      // 첫 리스트는 이곳에
      const [fixedCuration] = educationCurationList.slice(0, 1);
      fixedCuration && Object.assign(this.$data, fixedCuration);

      // 남은 리스트는 스토어에 저장해 다른 곳에서 사용합니다.
      this.setFixedCuration(educationCurationList.slice(1) || []);
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home', ['getFixedCurationList']),
    ...mapMutations({
      setFixedCuration: 'new-home/education/SET_FIXED_CURATION',
    }),
  },
};

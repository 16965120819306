
import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { mapActions } from 'vuex';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';

import ProductCuration from '@/components/product-curation/ProductCuration.vue';

export default {
  name: 'NewClassSection',
  components: { ProductCuration },
  props: {
    isTalent: { type: Boolean, default: false },
  },
  data() {
    return {
      newClassList: [],
      homeType: HOME_TYPE,
    };
  },
  fetchOnServer: false,
  async fetch() {
    const payload = this.isTalent ? { isTalent: true } : { isEducation: true };
    this.newClassList = await this.getNewClass(payload);
  },
  computed: {
    newClassSectionName: () => NEW_HOME_SECTION_NAME.NEW_CLASS,
  },
  watch: {
    homeFilterAge() {
      this.$fetch();
    },
  },
  methods: {
    ...mapActions('new-home', ['getNewClass']),
  },
};

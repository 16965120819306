
/**
 * @description
 * 홈 > 재능 > 친구들이 많이 찾는 테마 > 각 아이템
 */

import { CLICK_CATEGORY } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';

export default {
  name: 'TrendingCategoryItem',
  props: {
    id: { type: Number, default: 0, required: true },
    parentId: { type: Number, default: 0 },
    parentName: { type: String, default: null },
    name: { type: String, default: null, required: true },
  },
  methods: {
    logClickCategory() {
      this.logUserCustomEvent(CLICK_CATEGORY, {
        main_category_id: this.parentId,
        main_category_name: this.parentName,
        sub_category_id: this.id,
        sub_category_name: this.name,
        section: NEW_HOME_SECTION_NAME.TALENT_CATEGORY,
      });
    },
  },
};

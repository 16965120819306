
/**
 * @description
 * 단일 단어 위에 강조표기
 */
export default {
  name: 'StaccatoChar',
  props: {
    text: { type: String, default: null },
  },
};


/**
 * @description
 * 홈 > 재능 > 친구들이 많이 찾는 테마
 */

import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { mapActions } from 'vuex';

import TrendingCategoryItem from './TrendingCategoryItem.vue';

export default {
  name: 'TrendingCategory',
  components: { TrendingCategoryItem },
  data: () => {
    return {
      sectionName: NEW_HOME_SECTION_NAME.TALENT_CATEGORY,
      trendingCategoryList: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.trendingCategoryList = await this.getTrendingCategoryList();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('new-home/talent', ['getTrendingCategoryList']),
  },
};

/**
 * @description
 * 랭킹 페이지 관련 유틸리티와 enums 로직 모은 파일
 */

export const learningTypeFilter = [
  { text: '재능', value: 'TALENT' },
  { text: '학습', value: 'EDUCATION' },
];

export const periodTypeFilter = [
  { text: '주간', value: 'WEEKLY' },
  { text: '월간', value: 'MONTHLY' },
];

export const rankingMainFilter = [
  {
    text: '인기 랭킹',
    value: 'POPULARITY',
  },
  {
    text: '카테고리별',
    value: 'CLASS_CATEGORY',
  },
  {
    text: '수업유형별',
    value: 'CLASS_TYPE',
  },
  {
    text: '학년범위별',
    value: 'STUDENT_GRADE',
  },
];

const CLASS_CATEGORY = {
  TALENT: [
    { text: '국어', value: 'KOREAN' },
    { text: '영어', value: 'ENGLISH' },
    { text: '수학', value: 'MATHEMATICS' },
    { text: '예술활동', value: 'ART_ACTIVITIES' },
    { text: '인문/사회', value: 'HUMANITIES_AND_SOCIAL' },
    { text: '취미활동', value: 'HOBBIES' },
    { text: '자연/과학', value: 'NATURAL_SCIENCES' },
    { text: '진단/상담', value: 'ASSESSMENT_COUNSELING' },
    { text: '코딩', value: 'CODING' },
    // {
    //   text: '유학/국제학교',
    //   value: 'STUDY_ABROAD_AND_INTERNATIONAL_SCHOOLS',
    // },
  ],
  EDUCATION: [
    { text: '국어', value: 'KOREAN' },
    { text: '영어', value: 'ENGLISH' },
    { text: '수학', value: 'MATHEMATICS' },
    { text: '인문/사회', value: 'HUMANITIES_AND_SOCIAL' },
    { text: '자연/과학', value: 'NATURAL_SCIENCES' },
  ],
};

const STUDENT_GRADE = {
  TALENT: [
    { text: '예비초등', value: 'PRE_ELEMENTARY' },
    { text: '초등 저학년', value: 'LOWER_ELEMENTARY' },
    { text: '초등 고학년', value: 'UPPER_ELEMENTARY' },
    { text: '중등 이상', value: 'MIDDLE_SCHOOL_PLUS' },
  ],
  EDUCATION: [
    { text: '초등 저학년', value: 'LOWER_ELEMENTARY' },
    { text: '초등 고학년', value: 'UPPER_ELEMENTARY' },
    { text: '중등 이상', value: 'MIDDLE_SCHOOL_PLUS' },
  ],
};

export const getSubFilter = ({ selectedLearningType, selectedMainFilter }) => {
  switch (selectedMainFilter) {
    case 'POPULARITY':
      return [
        { text: '급상승', value: 'TRENDING' },
        { text: '베스트', value: 'BEST' },
        { text: '신규 수업', value: 'NEW_RELEASE' },
      ];
    case 'CLASS_CATEGORY':
      return CLASS_CATEGORY[selectedLearningType];
    case 'CLASS_TYPE':
      return [
        { text: '원데이', value: 'ONEDAY' },
        { text: '다회차', value: 'BUNDLE' },
        { text: '구독', value: 'SUBSCRIBE' },
      ];
    case 'STUDENT_GRADE':
      return STUDENT_GRADE[selectedLearningType];
    default:
      return null;
  }
};

/**
 * @description 랭킹 페이지 타이틀 만들어주는 유틸 함수
 * @returns (주간|월간) (메인 필터 text) 랭킹
 */
export const getPageTitle = ({ mainFilterType, periodType }) => {
  const mainFilterText =
    rankingMainFilter.find(mf => mf.value === mainFilterType)?.text || '';
  const periodTypeText =
    periodTypeFilter.find(pt => pt.value === periodType)?.text || '';

  // ex 주간 인기 랭킹 랭킹
  return `${periodTypeText} ${mainFilterText} 랭킹`;
};

/**
 * @description 랭킹 페이지 description
 */
export const RANKING_PAGE_DESCRIPTION =
  '꾸그에서 가장 핫한 수업만 모았다! 재능 수업부터 학습 수업까지, 아이들에게 가장 인기 있는 수업을 지금 랭킹에서 확인하세요.';

/**
 * @description 주요 필터별 랭킹조회 api에 사용하는 쿼리 빌더
 * @returns { learningType, periodType, majorFilterType }
 */
export const getMajorFilterQuery = ({
  selectedLearningType,
  selectedMainFilter,
  selectedPeriodFilter,
}) => ({
  learningType: selectedLearningType,
  periodType: selectedPeriodFilter,
  majorFilterType: selectedMainFilter,
});

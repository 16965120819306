
/**
 * @description
 * 홈 > 비로그인 유저에게 보이는 최하단 영역
 */

import SocialProof from './SocialProof.vue';
import SocialProofImage from './SocialProofImage.vue';

export default {
  name: 'UnAuthBottom',
  components: { SocialProofImage, SocialProof },
  data() {
    return {
      socialProofDom: null,
      socialProofObserver: null,
      isIntersecting: false,
    };
  },
  mounted() {
    this.socialProofDom = this.$refs.social;

    this.socialProofObserver = new IntersectionObserver(
      entries => {
        entries.forEach(el => {
          if (el.isIntersecting) {
            this.isIntersecting = true;
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    this.socialProofDom &&
      this.socialProofObserver.observe(this.socialProofDom);
  },
};

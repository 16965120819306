
/**
 * @description
 * 홈 랭킹 > 리스트를 받아와 그리는 랭킹 큐레이션
 *
 * 모바일 / 데스크탑 버전이 달라야함
 */

import NoRankingItemError from './NoRankingItemError.vue';
import RankingCurationDesktop from './desktop/RankingCurationDesktop.vue';
import RankingCurationMobile from './mobile/RankingCurationMobile.vue';

export default {
  name: 'RankingCurationWrapper',
  components: {
    NoRankingItemError,
    RankingCurationDesktop,
    RankingCurationMobile,
  },
  props: {
    rankingClassList: { type: Array, required: true, default: () => [] },
  },
  methods: {
    handleClickMore() {
      this.$emit('handle-click-more');
    },
    logClickEvent(rankingItem) {
      this.$emit('log-click-event', rankingItem);
    },
  },
};


/**
 * @description
 * 홈 화면 > 비로그인 유저가 보는 소셜 프루프 영역
 */

import { COMPONENT_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { mapActions } from 'vuex';

import IncreaseNumber from '@/components/common/IncreaseNumber.vue';

export default {
  name: 'SocialProof',
  components: { IncreaseNumber },
  props: {
    startAnim: { type: Boolean, default: false },
  },
  data: ({ getRandom1to4 }) => {
    return {
      yearMonth: '2024-08',
      userCount: {
        count: 112197,
        duration: getRandom1to4(),
      },
      teacherCount: {
        count: 714,
        duration: getRandom1to4(),
      },
      classCount: {
        count: 4366,
        duration: getRandom1to4(),
      },
      sectionName: COMPONENT_NAME.homeSocialProof,
    };
  },
  async fetch() {
    try {
      const { yearMonth, userCount, teacherCount, classCount } =
        await this.getSocialProofData();

      this.yearMonth = yearMonth;
      this.userCount.count = userCount;
      this.teacherCount.count = teacherCount;
      this.classCount.count = classCount;
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    yearMonthString: ({ yearMonth }) => {
      const [yearString, monthString] = yearMonth.split('-');
      const year = parseInt(yearString.slice(-2));
      const month = parseInt(monthString);

      // 변환 오류시 기본값으로 24년 8월을 보냅니다.
      if (isNaN(year) || isNaN(month)) return '24년 8월 기준';

      return `${year}년 ${month}월 기준`;
    },
  },
  methods: {
    ...mapActions('home', ['getSocialProofData']),
    getRandom1to4() {
      return Math.floor(Math.random() * 30) / 10 + 1;
    },
    onClickSignup() {
      this.logSignUpEvent(this.sectionName);
      this.requestToken({ section: this.sectionName });
    },
  },
};


/**
 * @description
 * 홈 > 재능 > 친구들이 남긴 후기 큐레이션
 */
import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { CLICK_REVIEW_ITEM } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { mapActions } from 'vuex';

import CurationProgressSection from '@/components/common/CurationProgressSection.vue';
import Swiper from '@/components/common/swiper/CssSwiper.vue';
import ReviewItem from './ReviewItem.vue';

export default {
  name: 'ReviewCuration',
  components: { Swiper, ReviewItem, CurationProgressSection },
  data() {
    return {
      reviewList: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.reviewList = await this.getTalentReviewListWithImage();
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    reviewSectionName: () => NEW_HOME_SECTION_NAME.REVIEW,
  },
  methods: {
    ...mapActions('new-home/talent', ['getTalentReviewListWithImage']),
    logClickReviewEvent(productId) {
      this.logUserCustomEvent(CLICK_REVIEW_ITEM, {
        product_id: productId,
        section: this.reviewSectionName,
      });
    },
  },
};

// eslint-disable-next-line
const EMOJI_REGEX = /^:.*:$/;

const GGURI_EMOJI = [
  {
    imgSrcId: 'gguri_happy.png',
    emojiId: ':꾸리_행복해요:',
  },
  {
    imgSrcId: 'gguri_proud.png',
    emojiId: ':꾸리_뿌듯해요:',
  },
  {
    imgSrcId: 'gguri_sad.png',
    emojiId: ':꾸리_속상해요:',
  },
  {
    imgSrcId: 'gguri_hard.png',
    emojiId: ':꾸리_힘들어요:',
  },
  {
    imgSrcId: 'gguri_expect.png',
    emojiId: ':꾸리_기대돼요:',
  },
  {
    imgSrcId: 'gguri_angry.png',
    emojiId: ':꾸리_화가나요:',
  },
];

const emojiList = [...GGURI_EMOJI];
const emojiIdList = emojiList.map(e => e.emojiId);

/**
 * @description
 * 이모지 전체 리스트 가져오기
 */
const getEmojiList = () => [...emojiList];

/**
 * @description 이모지 규약에 맞는지 & 실제로 있는 이모지인지 체크
 * @param {*} id
 * @returns true | false
 */
const checkIsEmoji = id => {
  if (!id) return false;
  if (!emojiIdList.includes(id)) return false;
  if (!EMOJI_REGEX.test(id)) return false;

  return true;
};

/**
 * @description
 * id에 맞는 이모지 src를 리턴
 */
const getEmojiSrc = id => {
  if (!checkIsEmoji(id)) return null;

  const currentEmoji = emojiList.find(e => e.emojiId === id);

  if (!currentEmoji) return null;

  const { imgSrcId } = currentEmoji;

  return imgSrcId;
};

/**
 * @description
 * 랜덤으로 뒤섞인 이모지 ID 리스트를 리턴
 */
const getShuffledList = () => {
  const emojiList = getEmojiList();
  const shuffledList = [];

  /**
   * Fisher-Yates shuffle
   * 이모지 리스트 길이가 0이 될때까지 랜덤으로 하나씩 뽑은 후 리턴
   */
  while (emojiList.length) {
    const lastIndex = emojiList.length - 1;
    const currentIndex = Math.floor(Math.random() * emojiList.length);
    const temp = emojiList[lastIndex];

    // 마지막 요소와 랜덤으로 선택된 요소를 교체
    emojiList[lastIndex] = emojiList[currentIndex];
    emojiList[currentIndex] = temp;

    // 마지막 요소를 pop
    const emojiItem = emojiList.pop();

    // 랜덤리스트에 push
    shuffledList.push(emojiItem);
  }

  return shuffledList;
};

const ggugeEmoji = {
  getEmojiList,
  checkIsEmoji,
  getEmojiSrc,
  getShuffledList,
};

export default ggugeEmoji;

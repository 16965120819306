
/**
 * @description
 * 홈 > 재능 > 친구들이 남긴 후기 큐레이션 아이템
 */
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';

import CommonRating from '@/components/common/CommonRating.vue';
import CommonLikeCount from '@/components/common/CommonLikeCount.vue';
import CommonProfile from '@/components/common/CommonProfile.vue';

export default {
  name: 'ReviewItem',
  components: { CommonRating, CommonLikeCount, CommonProfile },
  props: {
    comment: { type: String, required: true },
    name: { type: String, required: true },
    classStartDateTime: { type: String, required: true },
    rating: { type: Number, required: true },
    reviewCount: { type: Number, required: true },
    favoriteCount: { type: Number, required: true },
    title: { type: String, required: true },
    teacherName: { type: String, required: true },
    teacherImg: { type: String, required: true },
    image: { type: String, required: true },
  },
  data() {
    return {
      defaultImgUrl: '/images/development/default-thumbnail.jpg',
    };
  },
  computed: {
    convertedClassStartDateTime() {
      return makeDateTextFormatKo(
        new Date(this.classStartDateTime),
        'yyyy. MM. dd.',
      );
    },
    reviewImg() {
      if (!this.image || this.image.length === 0) return this.defaultImgUrl;

      return this.$imgWrapper(this.image, 300);
    },
  },
  methods: {
    onImageLoadFail(event) {
      event.target.src = this.defaultImgUrl;
    },
  },
};

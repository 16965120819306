import { render, staticRenderFns } from "./CategoryItem.vue?vue&type=template&id=10a5b39d&scoped=true"
import script from "./CategoryItem.vue?vue&type=script&lang=js"
export * from "./CategoryItem.vue?vue&type=script&lang=js"
import style0 from "./CategoryItem.vue?vue&type=style&index=0&id=10a5b39d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a5b39d",
  null
  
)

export default component.exports